/* General styling */
body {
  background-color: #f5f5dc; /* Warna krem lembut */
  color: #333; /* Warna teks abu-abu gelap */
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  padding: 20px;
}

header {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin-bottom: 40px;
}

/* Post List Styling */
.post-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.post-item {
  background-color: #ffffff; /* Warna putih */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.post-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.post-item h3 {
  margin-top: 10px;
  font-size: 1.2em;
}

.post-item p {
  font-size: 0.9em;
  color: #666;
  margin-top: 10px;
}

.post-item a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.post-item a:hover {
  color: #61dafb;
}

/* Post Detail Section */
.story-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.story-section {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.story-section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .story-section {
    width: 90%;
  }

  .post-item {
    width: 90%;
    margin: 10px 0;
  }
}

@media (max-width: 600px) {
  .post-item {
    width: 100%;
    margin: 10px 0;
  }
}

/* Language Toggle Styling */
.language-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4CAF50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.label-text {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.post-detail-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5dc; /* Warna krem yang nyaman di mata */
  color: #333; /* Warna teks yang jelas */
}

.post-detail-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #444;
}

.section {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.section-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 15px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section p {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
  text-align: justify; /* Menambahkan justify */
}

.back-link {
  margin: 20px 0;
  color: #61dafb;
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section {
    width: 90%;
  }

  .section h2 {
    font-size: 1.2em;
  }

  .section p {
    font-size: 0.9em;
  }
}

.section-content {
  font-size: 1em;
  line-height: 1.6;
  color: #dcdcdc;
  text-align: justify;
}

.section-content h1, .section-content h2, .section-content h3 {
  color: #ffffff; /* Sesuaikan warna heading dengan background gelap */
}

.section-content a {
  color: #61dafb;
  text-decoration: none;
}

.section-content a:hover {
  text-decoration: underline;
}

.section-content ul, .section-content ol {
  margin-left: 20px;
}

.donation-section {
  text-align: center;
  margin: 20px 0;
}

.donation-section h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.donation-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.page-button {
  padding: 8px 16px;
  margin: 0 5px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.page-button.active {
  background-color: #0056b3;
}

.page-button:hover {
  background-color: #0056b3;
}

.ad-section {
  margin-top: 40px;
  text-align: center;
  border: 2px dashed #ddd;
  padding: 20px;
  background-color: #f9f9f9;
}

.ad-banner {
  font-size: 16px;
  color: #333;
}

.ad-banner p {
  font-size: 16px;
  color: #333;
  text-align: center;
}